export const ItemCategory_SAVE_REQUEST = 'ItemCategory_SAVE_REQUEST';
export const ItemCategory_SAVE_SUCCESS = 'ItemCategory_SAVE_SUCCESS';
export const ItemCategory_SAVE_FAIL = 'ItemCategory_SAVE_FAIL';

export const ItemSubCategory_SAVE_REQUEST = 'ItemSubCategory_SAVE_REQUEST';
export const ItemSubCategory_SAVE_SUCCESS = 'ItemSubCategory_SAVE_SUCCESS';
export const ItemSubCategory_SAVE_FAIL = 'ItemSubCategory_SAVE_FAIL';

export const Charges_SAVE_REQUEST = 'Charges_SAVE_REQUEST';
export const Charges_SAVE_SUCCESS = 'Charges_SAVE_SUCCESS';
export const Charges_SAVE_FAIL = 'Charges_SAVE_FAIL';

export const ChargeSetup_SAVE_REQUEST = 'ChargeSetup_SAVE_REQUEST';
export const ChargeSetup_SAVE_SUCCESS = 'ChargeSetup_SAVE_SUCCESS';
export const ChargeSetup_SAVE_FAIL = 'ChargeSetup_SAVE_FAIL';

export const UnitMaster_SAVE_REQUEST = 'UnitMaster_SAVE_REQUEST';
export const UnitMaster_SAVE_SUCCESS = 'UnitMaster_SAVE_SUCCESS';
export const UnitMaster_SAVE_FAIL = 'UnitMaster_SAVE_FAIL';

export const ItemUnitMaster_SAVE_REQUEST = 'ItemUnitMaster_SAVE_REQUEST';
export const ItemUnitMaster_SAVE_SUCCESS = 'ItemUnitMaster_SAVE_SUCCESS';
export const ItemUnitMaster_SAVE_FAIL = 'ItemUnitMaster_SAVE_FAIL';

export const HSNGroup_SAVE_REQUEST = 'HSNGroup_SAVE_REQUEST';
export const HSNGroup_SAVE_SUCCESS = 'HSNGroup_SAVE_SUCCESS';
export const HSNGroup_SAVE_FAIL = 'HSNGroup_SAVE_FAIL';

export const HSNCode_SAVE_REQUEST = 'HSNCode_SAVE_REQUEST';
export const HSNCode_SAVE_SUCCESS = 'HSNCode_SAVE_SUCCESS';
export const HSNCode_SAVE_FAIL = 'HSNCode_SAVE_FAIL';

export const ItemMaster_SAVE_REQUEST = 'ItemMaster_SAVE_REQUEST';
export const ItemMaster_SAVE_SUCCESS = 'ItemMaster_SAVE_SUCCESS';
export const ItemMaster_SAVE_FAIL = 'ItemMaster_SAVE_FAIL';

export const NewItemRequest_SAVE_REQUEST = 'NewItemRequest_SAVE_REQUEST';
export const NewItemRequest_SAVE_SUCCESS = 'NewItemRequest_SAVE_SUCCESS';
export const NewItemRequest_SAVE_FAIL = 'NewItemRequest_SAVE_FAIL';

export const ItemCharges_SAVE_REQUEST = 'ItemCharges_SAVE_REQUEST';
export const ItemCharges_SAVE_SUCCESS = 'ItemCharges_SAVE_SUCCESS';
export const ItemCharges_SAVE_FAIL = 'ItemCharges_SAVE_FAIL';

export const ItemAttributes_SAVE_REQUEST = 'ItemAttributes_SAVE_REQUEST';
export const ItemAttributes_SAVE_SUCCESS = 'ItemAttributes_SAVE_SUCCESS';
export const ItemAttributes_SAVE_FAIL = 'ItemAttributes_SAVE_FAIL';

export const ItemwithItemUnitMapping_SAVE_REQUEST = 'ItemwithItemUnitMapping_SAVE_REQUEST';
export const ItemwithItemUnitMapping_SAVE_SUCCESS = 'ItemwithItemUnitMapping_SAVE_SUCCESS';
export const ItemwithItemUnitMapping_SAVE_FAIL = 'ItemwithItemUnitMapping_SAVE_FAIL';

export const ChargeTemplateMain_SAVE_REQUEST = 'ChargeTemplateMain_SAVE_REQUEST';
export const ChargeTemplateMain_SAVE_SUCCESS = 'ChargeTemplateMain_SAVE_SUCCESS';
export const ChargeTemplateMain_SAVE_FAIL = 'ChargeTemplateMain_SAVE_FAIL';

export const ChargeTemplateDetails_SAVE_REQUEST = 'ChargeTemplateDetails_SAVE_REQUEST';
export const ChargeTemplateDetails_SAVE_SUCCESS = 'ChargeTemplateDetails_SAVE_SUCCESS';
export const ChargeTemplateDetails_SAVE_FAIL = 'ChargeTemplateDetails_SAVE_FAIL';

export const TherapeuticCategory_SAVE_REQUEST = 'TherapeuticCategory_SAVE_REQUEST';
export const TherapeuticCategory_SAVE_SUCCESS = 'TherapeuticCategory_SAVE_SUCCESS';
export const TherapeuticCategory_SAVE_FAIL = 'TherapeuticCategory_SAVE_FAIL';

export const TherapeuticSubCategory_SAVE_REQUEST = 'TherapeuticSubCategory_SAVE_REQUEST';
export const TherapeuticSubCategory_SAVE_SUCCESS = 'TherapeuticSubCategory_SAVE_SUCCESS';
export const TherapeuticSubCategory_SAVE_FAIL = 'TherapeuticSubCategory_SAVE_FAIL';

export const GenericMaster_SAVE_REQUEST = 'GenericMaster_SAVE_REQUEST';
export const GenericMaster_SAVE_SUCCESS = 'GenericMaster_SAVE_SUCCESS';
export const GenericMaster_SAVE_FAIL = 'GenericMaster_SAVE_FAIL';

export const SaveEmpUpdateTimeForExportCSV_SAVE_REQUEST = 'SaveEmpUpdateTimeForExportCSV_SAVE_REQUEST';
export const SaveEmpUpdateTimeForExportCSV_SAVE_SUCCESS = 'SaveEmpUpdateTimeForExportCSV_SAVE_SUCCESS';
export const SaveEmpUpdateTimeForExportCSV_SAVE_FAIL = 'SaveEmpUpdateTimeForExportCSV_SAVE_FAIL';

export const ItemGenericMapping_SAVE_REQUEST = 'ItemGenericMapping_SAVE_REQUEST';
export const ItemGenericMapping_SAVE_SUCCESS = 'ItemGenericMapping_SAVE_SUCCESS';
export const ItemGenericMapping_SAVE_FAIL = 'ItemGenericMapping_SAVE_FAIL';

export const ItemFormMaster_SAVE_REQUEST = 'ItemFormMaster_SAVE_REQUEST';
export const ItemFormMaster_SAVE_SUCCESS = 'ItemFormMaster_SAVE_SUCCESS';
export const ItemFormMaster_SAVE_FAIL = 'ItemFormMaster_SAVE_FAIL';

export const ItemFacilityMapping_SAVE_REQUEST = 'ItemFacilityMapping_SAVE_REQUEST';
export const ItemFacilityMapping_SAVE_SUCCESS = 'ItemFacilityMapping_SAVE_SUCCESS';
export const ItemFacilityMapping_SAVE_FAIL = 'ItemFacilityMapping_SAVE_FAIL';

export const GRNMain_SAVE_REQUEST = 'GRNMain_SAVE_REQUEST';
export const GRNMain_SAVE_SUCCESS = 'GRNMain_SAVE_SUCCESS';
export const GRNMain_SAVE_FAIL = 'GRNMain_SAVE_FAIL';

export const GRNDetails_SAVE_REQUEST = 'GRNDetails_SAVE_REQUEST';
export const GRNDetails_SAVE_SUCCESS = 'GRNDetails_SAVE_SUCCESS';
export const GRNDetails_SAVE_FAIL = 'GRNDetails_SAVE_FAIL';

export const GRNItemCharges_SAVE_REQUEST = 'GRNItemCharges_SAVE_REQUEST';
export const GRNItemCharges_SAVE_SUCCESS = 'GRNItemCharges_SAVE_SUCCESS';
export const GRNItemCharges_SAVE_FAIL = 'GRNItemCharges_SAVE_FAIL';

export const GRNDocumentCharges_SAVE_REQUEST = 'GRNDocumentCharges_SAVE_REQUEST';
export const GRNDocumentCharges_SAVE_SUCCESS = 'GRNDocumentCharges_SAVE_SUCCESS';
export const GRNDocumentCharges_SAVE_FAIL = 'GRNDocumentCharges_SAVE_FAIL';

export const POMain_SAVE_REQUEST = 'POMain_SAVE_REQUEST';
export const POMain_SAVE_SUCCESS = 'POMain_SAVE_SUCCESS';
export const POMain_SAVE_FAIL = 'POMain_SAVE_FAIL';

export const WorkOrder_SAVE_REQUEST = 'WorkOrder_SAVE_REQUEST';
export const WorkOrder_SAVE_SUCCESS = 'WorkOrder_SAVE_SUCCESS';
export const WorkOrder_SAVE_FAIL = 'WorkOrder_SAVE_FAIL';

export const AddVaccination_SAVE_REQUEST = 'AddVaccination_SAVE_REQUEST';
export const AddVaccination_SAVE_SUCCESS = 'AddVaccination_SAVE_SUCCESS';
export const AddVaccination_SAVE_FAIL = 'AddVaccination_SAVE_FAIL';

export const PODetails_SAVE_REQUEST = 'PODetails_SAVE_REQUEST';
export const PODetails_SAVE_SUCCESS = 'PODetails_SAVE_SUCCESS';
export const PODetails_SAVE_FAIL = 'PODetails_SAVE_FAIL';

export const POItemCharges_SAVE_REQUEST = 'POItemCharges_SAVE_REQUEST';
export const POItemCharges_SAVE_SUCCESS = 'POItemCharges_SAVE_SUCCESS';
export const POItemCharges_SAVE_FAIL = 'POItemCharges_SAVE_FAIL';

export const PODocumentCharges_SAVE_REQUEST = 'PODocumentCharges_SAVE_REQUEST';
export const PODocumentCharges_SAVE_SUCCESS = 'PODocumentCharges_SAVE_SUCCESS';
export const PODocumentCharges_SAVE_FAIL = 'PODocumentCharges_SAVE_FAIL';

export const ReusableItem_SAVE_REQUEST = 'ReusableItem_SAVE_REQUEST';
export const ReusableItem_SAVE_SUCCESS = 'ReusableItem_SAVE_SUCCESS';
export const ReusableItem_SAVE_FAIL = 'ReusableItem_SAVE_FAIL';
//tony
export const ReusableItemCharges_SAVE_REQUEST = 'ReusableItemCharges_SAVE_REQUEST';
export const ReusableItemCharges_SAVE_SUCCESS = 'ReusableItemCharges_SAVE_SUCCESS';
export const ReusableItemCharges_SAVE_FAIL = 'ReusableItemCharges_SAVE_FAIL';

export const Stocks_SAVE_REQUEST = 'Stocks_SAVE_REQUEST';
export const Stocks_SAVE_SUCCESS = 'Stocks_SAVE_SUCCESS';
export const Stocks_SAVE_FAIL = 'Stocks_SAVE_FAIL';

export const PostCancelGRN_SAVE_REQUEST = 'PostCancelGRN_SAVE_REQUEST';
export const PostCancelGRN_SAVE_SUCCESS = 'PostCancelGRN_SAVE_SUCCESS';
export const PostCancelGRN_SAVE_FAIL = 'PostCancelGRN_SAVE_FAIL';

export const PrescriptionUnit_SAVE_REQUEST = 'PrescriptionUnit_SAVE_REQUEST';
export const PrescriptionUnit_SAVE_SUCCESS = 'PrescriptionUnit_SAVE_SUCCESS';
export const PrescriptionUnit_SAVE_FAIL = 'PrescriptionUnit_SAVE_FAIL';

export const ConsignmentMain_SAVE_REQUEST = 'ConsignmentMain_SAVE_REQUEST';
export const ConsignmentMain_SAVE_SUCCESS = 'ConsignmentMain_SAVE_SUCCESS';
export const ConsignmentMain_SAVE_FAIL = 'ConsignmentMain_SAVE_FAIL';

export const ConsignmentDetails_SAVE_REQUEST = 'ConsignmentDetails_SAVE_REQUEST';
export const ConsignmentDetails_SAVE_SUCCESS = 'ConsignmentDetails_SAVE_SUCCESS';
export const ConsignmentDetails_SAVE_FAIL = 'ConsignmentDetails_SAVE_FAIL';

export const ConsignmentItemCharges_SAVE_REQUEST = 'ConsignmentItemCharges_SAVE_REQUEST';
export const ConsignmentItemCharges_SAVE_SUCCESS = 'ConsignmentItemCharges_SAVE_SUCCESS';
export const ConsignmentItemCharges_SAVE_FAIL = 'ConsignmentItemCharges_SAVE_FAIL';

export const PostCancelConsignment_SAVE_REQUEST = 'PostCancelConsignment_SAVE_REQUEST';
export const PostCancelConsignment_SAVE_SUCCESS = 'PostCancelConsignment_SAVE_SUCCESS';
export const PostCancelConsignment_SAVE_FAIL = 'PostCancelConsignment_SAVE_FAIL';


export const ConsignmentReceiveMain_SAVE_REQUEST = 'ConsignmentReceiveMain_SAVE_REQUEST';
export const ConsignmentReceiveMain_SAVE_SUCCESS = 'ConsignmentReceiveMain_SAVE_SUCCESS';
export const ConsignmentReceiveMain_SAVE_FAIL = 'ConsignmentReceiveMain_SAVE_FAIL';

export const ConsignmentReceiveDetails_SAVE_REQUEST = 'ConsignmentReceiveDetails_SAVE_REQUEST';
export const ConsignmentReceiveDetails_SAVE_SUCCESS = 'ConsignmentReceiveDetails_SAVE_SUCCESS';
export const ConsignmentReceiveDetails_SAVE_FAIL = 'ConsignmentReceiveDetails_SAVE_FAIL';

export const ConsignmentReceiveItemCharges_SAVE_REQUEST = 'ConsignmentReceiveItemCharges_SAVE_REQUEST';
export const ConsignmentReceiveItemCharges_SAVE_SUCCESS = 'ConsignmentReceiveItemCharges_SAVE_SUCCESS';
export const ConsignmentReceiveItemCharges_SAVE_FAIL = 'ConsignmentReceiveItemCharges_SAVE_FAIL';

export const saveConsignmentAllocation_SAVE_REQUEST = 'saveConsignmentAllocation_SAVE_REQUEST';
export const saveConsignmentAllocation_SAVE_SUCCESS = 'saveConsignmentAllocation_SAVE_SUCCESS';
export const saveConsignmentAllocation_SAVE_FAIL = 'saveConsignmentAllocation_SAVE_FAIL';

export const saveConsignmentReturn_SAVE_REQUEST = 'saveConsignmentReturn_SAVE_REQUEST';
export const saveConsignmentReturn_SAVE_SUCCESS = 'saveConsignmentReturn_SAVE_SUCCESS';
export const saveConsignmentReturn_SAVE_FAIL = 'saveConsignmentReturn_SAVE_FAIL';

//----------------------------SHASHI------------------------------------

export const POAPPROVALSETUP_SAVE_REQUEST = 'POAPPROVALSETUP_SAVE_REQUEST';
export const POAPPROVALSETUP_SAVE_SUCCESS = 'POAPPROVALSETUP_SAVE_SUCCESS';
export const POAPPROVALSETUP_SAVE_FAIL = 'POAPPROVALSETUP_SAVE_FAIL';

export const SUPPLIERDETAIL_SAVE_REQUEST = 'SUPPLIERDETAIL_SAVE_REQUEST';
export const SUPPLIERDETAIL_SAVE_SUCCESS = 'SUPPLIERDETAIL_SAVE_SUCCESS';
export const SUPPLIERDETAIL_SAVE_FAIL = 'SUPPLIERDETAIL_SAVE_FAIL';

export const SUPPLIERTERMSCONDITION_SAVE_REQUEST = 'SUPPLIERTERMSCONDITION_SAVE_REQUEST';
export const SUPPLIERTERMSCONDITION_SAVE_SUCCESS = 'SUPPLIERTERMSCONDITION_SAVE_SUCCESS';
export const SUPPLIERTERMSCONDITION_SAVE_FAIL = 'SUPPLIERTERMSCONDITION_SAVE_FAIL';

export const SUPPLIER_FACILITY_MAPPING_SAVE_REQUEST = 'SUPPLIER_FACILITY_MAPPING_SAVE_REQUEST';
export const SUPPLIER_FACILITY_MAPPING_SAVE_SUCCESS = 'SUPPLIER_FACILITY_MAPPING_SAVE_SUCCESS';
export const SUPPLIER_FACILITY_MAPPING_SAVE_FAIL = 'SUPPLIER_FACILITY_MAPPING_SAVE_FAIL';

export const TERMS_CONDITION_SAVE_REQUEST = 'TERMS_CONDITION_SAVE_REQUEST';
export const TERMS_CONDITION_SAVE_SUCCESS = 'TERMS_CONDITION_SAVE_SUCCESS';
export const TERMS_CONDITION_SAVE_FAIL = 'TERMS_CONDITION_SAVE_FAIL';

export const SUPPLIER_ITEM_MAPPING_SAVE_REQUEST = 'SUPPLIER_ITEM_MAPPING_SAVE_REQUEST';
export const SUPPLIER_ITEM_MAPPING_SAVE_SUCCESS = 'SUPPLIER_ITEM_MAPPING_SAVE_SUCCESS';
export const SUPPLIER_ITEM_MAPPING_SAVE_FAIL = 'SUPPLIER_ITEM_MAPPING_SAVE_FAIL';

export const SUPPLIER_LOGIN_DETAIL_SAVE_REQUEST = 'SUPPLIER_LOGIN_DETAIL_SAVE_REQUEST';
export const SUPPLIER_LOGIN_DETAIL_SAVE_SUCCESS = 'SUPPLIER_LOGIN_DETAIL_SAVE_SUCCESS';
export const SUPPLIER_LOGIN_DETAIL_SAVE_FAIL = 'SUPPLIER_LOGIN_DETAIL_SAVE_FAIL';

export const ManufacturerSupplierMapping_SAVE_REQUEST = 'ManufacturerSupplierMapping_SAVE_REQUEST';
export const ManufacturerSupplierMapping_SAVE_SUCCESS = 'ManufacturerSupplierMapping_SAVE_SUCCESS';
export const ManufacturerSupplierMapping_SAVE_FAIL = 'ManufacturerSupplierMapping_SAVE_FAIL';

export const ItemWisePOClose_SAVE_REQUEST = 'ItemWisePOClose_SAVE_REQUEST';
export const ItemWisePOClose_SAVE_SUCCESS = 'ItemWisePOClose_SAVE_SUCCESS';
export const ItemWisePOClose_SAVE_FAIL = 'ItemWisePOClose_SAVE_FAIL';

//------------------------------------------
export const STORE_SAVE_REQUEST = 'STORE_SAVE_REQUEST';
export const STORE_SAVE_SUCCESS = 'STORE_SAVE_SUCCESS';
export const STORE_SAVE_FAIL = 'STORE_SAVE_FAIL';

export const STORE_AFFILLIATION_SAVE_REQUEST = 'STORE_AFFILLIATION_SAVE_REQUEST';
export const STORE_AFFILLIATION_SAVE_SUCCESS = 'STORE_AFFILLIATION_SAVE_SUCCESS';
export const STORE_AFFILLIATION_SAVE_FAIL = 'STORE_AFFILLIATION_SAVE_FAIL';

export const STORE_AUTO_INDENT_FREQUENCY_SAVE_REQUEST = 'STORE_AUTO_INDENT_FREQUENCY_SAVE_REQUEST';
export const STORE_AUTO_INDENT_FREQUENCY_SAVE_SUCCESS = 'STORE_AUTO_INDENT_FREQUENCY_SAVE_SUCCESS';
export const STORE_AUTO_INDENT_FREQUENCY_SAVE_FAIL = 'STORE_AUTO_INDENT_FREQUENCY_SAVE_FAIL';

export const STORE_ITEM_MAPPING_SAVE_REQUEST = 'STORE_ITEM_MAPPING_SAVE_REQUEST';
export const STORE_ITEM_MAPPING_SAVE_SUCCESS = 'STORE_ITEM_MAPPING_SAVE_SUCCESS';
export const STORE_ITEM_MAPPING_SAVE_FAIL = 'STORE_ITEM_MAPPING_SAVE_FAIL';

export const STORE_ItemAutoConsumption_SAVE_REQUEST = 'STORE_ItemAutoConsumption_SAVE_REQUEST';
export const STORE_ItemAutoConsumption_SAVE_SUCCESS = 'STORE_ItemAutoConsumption_SAVE_SUCCESS';
export const STORE_ItemAutoConsumption_SAVE_FAIL = 'STORE_ItemAutoConsumption_SAVE_FAIL';

export const STORE_ROLSETUP_SAVE_REQUEST = 'STORE_ROLSETUP_SAVE_REQUEST';
export const STORE_ROLSETUP_SAVE_SUCCESS = 'STORE_ROLSETUP_SAVE_SUCCESS';
export const STORE_ROLSETUP_SAVE_FAIL = 'STORE_ROLSETUP_SAVE_FAIL';

export const STORE_TRANSACTIONTYPE_SAVE_REQUEST = 'STORE_TRANSACTIONTYPE_SAVE_REQUEST';
export const STORE_TRANSACTIONTYPE_SAVE_SUCCESS = 'STORE_TRANSACTIONTYPE_SAVE_SUCCESS';
export const STORE_TRANSACTIONTYPE_SAVE_FAIL = 'STORE_TRANSACTIONTYPE_SAVE_FAIL';

export const BINLOCATION_SAVE_REQUEST = 'BINLOCATION_SAVE_REQUEST';
export const BINLOCATION_SAVE_SUCCESS = 'BINLOCATION_SAVE_SUCCESS';
export const BINLOCATION_SAVE_FAIL = 'BINLOCATION_SAVE_FAIL';
export const ITEM_SALE_PRICE_SETUP_SAVE_REQUEST =
  "ITEM_SALE_PRICE_SETUP_SAVE_REQUEST";
export const ITEM_SALE_PRICE_SETUP_SAVE_SUCCESS =
  "ITEM_SALE_PRICE_SETUP_SAVE_SUCCESS";
export const ITEM_SALE_PRICE_SETUP_SAVE_FAIL =
  "ITEM_SALE_PRICE_SETUP_SAVE_FAIL";

export const KITMASTER_SAVE_REQUEST = "KITMASTER_SAVE_REQUEST";
export const KITMASTER_SAVE_SUCCESS = "KITMASTER_SAVE_SUCCESS";
export const KITMASTER_SAVE_FAIL = "KITMASTER_SAVE_FAIL";

export const KIT_ITEM_MAPPING_SAVE_REQUEST = "KIT_ITEM_MAPPING_SAVE_REQUEST";
export const KIT_ITEM_MAPPING_SAVE_SUCCESS = "KIT_ITEM_MAPPING_SAVE_SUCCESS";
export const KIT_ITEM_MAPPING_SAVE_FAIL = "KIT_ITEM_MAPPING_SAVE_FAIL";

export const DEPARTMENT_INDENT_SAVE_REQUEST = "DEPARTMENT_INDENT_SAVE_REQUEST";
export const DEPARTMENT_INDENT_SAVE_SUCCESS = "DEPARTMENT_INDENT_SAVE_SUCCESS";
export const DEPARTMENT_INDENT_SAVE_FAIL = "DEPARTMENT_INDENT_SAVE_FAIL";

export const DepartmentIndentApprovalOrRejection_SAVE_REQUEST = "DepartmentIndentApprovalOrRejection_SAVE_REQUEST";
export const DepartmentIndentApprovalOrRejection_SAVE_SUCCESS = "DepartmentIndentApprovalOrRejection_SAVE_SUCCESS";
export const DepartmentIndentApprovalOrRejection_SAVE_FAIL = "DepartmentIndentApprovalOrRejection_SAVE_FAIL";

export const DepartmentIndent_Allocation_SAVE_REQUEST = "DepartmentIndent_Allocation_SAVE_REQUEST";
export const DepartmentIndent_Allocation_SAVE_SUCCESS = "DepartmentIndent_Allocation_SAVE_SUCCESS";
export const DepartmentIndent_Allocation_SAVE_FAIL = "DepartmentIndent_Allocation_SAVE_FAIL";

export const DEPARTMENTISSUE_SAVE_REQUEST = "DEPARTMENTISSUE_SAVE_REQUEST";
export const DEPARTMENTISSUE_SAVE_SUCCESS = "DEPARTMENTISSUE_SAVE_SUCCESS";
export const DEPARTMENTISSUE_SAVE_FAIL = "DEPARTMENTISSUE_SAVE_FAIL";

export const INDENTRECEIVE_SAVE_REQUEST = "INDENTRECEIVE_SAVE_REQUEST";
export const INDENTRECEIVE_SAVE_SUCCESS = "INDENTRECEIVE_SAVE_SUCCESS";
export const INDENTRECEIVE_SAVE_FAIL = "INDENTRECEIVE_SAVE_FAIL";

export const INDENT_RECEIVE_RETURN_SAVE_REQUEST = "INDENT_RECEIVE_RETURN_SAVE_REQUEST";
export const INDENT_RECEIVE_RETURN_SAVE_SUCCESS = "INDENT_RECEIVE_RETURN_SAVE_SUCCESS";
export const INDENT_RECEIVE_RETURN_SAVE_FAIL = "INDENT_RECEIVE_RETURN_SAVE_FAIL";

export const INDENTRETURN_SAVE_REQUEST = "INDENTRETURN_SAVE_REQUEST";
export const INDENTRETURN_SAVE_SUCCESS = "INDENTRETURN_SAVE_SUCCESS";
export const INDENTRETURN_SAVE_FAIL = "INDENTRETURN_SAVE_FAIL";

export const INDENTRETURN_ACK_SAVE_REQUEST = "INDENTRETURN_ACK_SAVE_REQUEST";
export const INDENTRETURN_ACK_SAVE_SUCCESS = "INDENTRETURN_ACK_SAVE_SUCCESS";
export const INDENTRETURN_ACK_SAVE_FAIL = "INDENTRETURN_ACK_SAVE_FAIL";

export const INDENTRETURN_CANCEL_SAVE_REQUEST = "INDENTRETURN_CANCEL_SAVE_REQUEST";
export const INDENTRETURN_CANCEL_SAVE_SUCCESS = "INDENTRETURN_CANCEL_SAVE_SUCCESS";
export const INDENTRETURN_CANCEL_SAVE_FAIL = "INDENTRETURN_CANCEL_SAVE_FAIL";

export const PURCHASEINDENT_SAVE_REQUEST = "PURCHASEINDENT_SAVE_REQUEST";
export const PURCHASEINDENT_SAVE_SUCCESS = "PURCHASEINDENT_SAVE_SUCCESS";
export const PURCHASEINDENT_SAVE_FAIL = "PURCHASEINDENT_SAVE_FAIL";

export const PURCHASEINDENT_APPROVE_REJECT_SAVE_REQUEST = "PURCHASEINDENT_APPROVE_REJECT_SAVE_REQUEST";
export const PURCHASEINDENT_APPROVE_REJECT_SAVE_SUCCESS = "PURCHASEINDENT_APPROVE_REJECT_SAVE_SUCCESS";
export const PURCHASEINDENT_APPROVE_REJECT_SAVE_FAIL = "PURCHASEINDENT_APPROVE_REJECT_SAVE_FAIL";

export const saveDepartmentConsumption_SAVE_REQUEST = "saveDepartmentConsumption_SAVE_REQUEST";
export const saveDepartmentConsumption_SAVE_SUCCESS = "saveDepartmentConsumption_SAVE_SUCCESS";
export const saveDepartmentConsumption_SAVE_FAIL = "saveDepartmentConsumption_SAVE_FAIL";

export const SAVEIPISSUE_SAVE_REQUEST = "SAVEIPISSUE_SAVE_REQUEST";
export const SAVEIPISSUE_SAVE_SUCCESS = "SAVEIPISSUE_SAVE_SUCCESS";
export const SAVEIPISSUE_SAVE_FAIL = "SAVEIPISSUE_SAVE_FAIL";

export const SAVEIPIssueAllocation_SAVE_REQUEST = "SAVEIPIssueAllocation_SAVE_REQUEST";
export const SAVEIPIssueAllocation_SAVE_SUCCESS = "SAVEIPIssueAllocation_SAVE_SUCCESS";
export const SAVEIPIssueAllocation_SAVE_FAIL = "SAVEIPIssueAllocation_SAVE_FAIL";

export const SAVERxWardAcknowledge_SAVE_REQUEST = "SAVERxWardAcknowledge_SAVE_REQUEST";
export const SAVERxWardAcknowledge_SAVE_SUCCESS = "SAVERxWardAcknowledge_SAVE_SUCCESS";
export const SAVERxWardAcknowledge_SAVE_FAIL = "SAVERxWardAcknowledge_SAVE_FAIL";

export const SAVEIPISSUERETURN_SAVE_REQUEST = "SAVEIPISSUERETURN_SAVE_REQUEST";
export const SAVEIPISSUERETURN_SAVE_SUCCESS = "SAVEIPISSUERETURN_SAVE_SUCCESS";
export const SAVEIPISSUERETURN_SAVE_FAIL = "SAVEIPISSUERETURN_SAVE_FAIL";

export const saveSupplierReturn_SAVE_REQUEST = "saveSupplierReturn_SAVE_REQUEST";
export const saveSupplierReturn_SAVE_SUCCESS = "saveSupplierReturn_SAVE_SUCCESS";
export const saveSupplierReturn_SAVE_FAIL = "saveSupplierReturn_SAVE_FAIL";

export const SaveQuotation_SAVE_REQUEST = "SaveQuotation_SAVE_REQUEST";
export const SaveQuotation_SAVE_SUCCESS = "SaveQuotation_SAVE_SUCCESS";
export const SaveQuotation_SAVE_FAIL = "SaveQuotation_SAVE_FAIL";

export const UpdateQuotationStatus_SAVE_REQUEST = "UpdateQuotationStatus_SAVE_REQUEST";
export const UpdateQuotationStatus_SAVE_SUCCESS = "UpdateQuotationStatus_SAVE_SUCCESS";
export const UpdateQuotationStatus_SAVE_FAIL = "UpdateQuotationStatus_SAVE_FAIL";

export const InActiveQuotationItems_SAVE_REQUEST = "InActiveQuotationItems_SAVE_REQUEST";
export const InActiveQuotationItems_SAVE_SUCCESS = "InActiveQuotationItems_SAVE_SUCCESS";
export const InActiveQuotationItems_SAVE_FAIL = "InActiveQuotationItems_SAVE_FAIL";

export const SaveConsignmentIssue_SAVE_REQUEST = "SaveConsignmentIssue_SAVE_REQUEST";
export const SaveConsignmentIssue_SAVE_SUCCESS = "SaveConsignmentIssue_SAVE_SUCCESS";
export const SaveConsignmentIssue_SAVE_FAIL = "SaveConsignmentIssue_SAVE_FAIL";

export const SaveConsignmentIssueReturnAsync_SAVE_REQUEST = "SaveConsignmentIssueReturnAsync_SAVE_REQUEST";
export const SaveConsignmentIssueReturnAsync_SAVE_SUCCESS = "SaveConsignmentIssueReturnAsync_SAVE_SUCCESS";
export const SaveConsignmentIssueReturnAsync_SAVE_FAIL = "SaveConsignmentIssueReturnAsync_SAVE_FAIL";

export const DocumentsUpload_SAVE_REQUEST = "DocumentsUpload_SAVE_REQUEST";
export const DocumentsUpload_SAVE_SUCCESS = "DocumentsUpload_SAVE_SUCCESS";
export const DocumentsUpload_SAVE_FAIL = "DocumentsUpload_SAVE_FAIL";

export const DeleteDocument_SAVE_REQUEST = "DeleteDocument_SAVE_REQUEST";
export const DeleteDocument_SAVE_SUCCESS = "DeleteDocument_SAVE_SUCCESS";
export const DeleteDocument_SAVE_FAIL = "DeleteDocument_SAVE_FAIL";
//-------------------------SHRIKANT SAINI-----------------------------
export const OPIssueSaleMain_SAVE_REQUEST = 'OPIssueSaleMain_SAVE_REQUEST';
export const OPIssueSaleMain_SAVE_SUCCESS = 'OPIssueSaleMain_SAVE_SUCCESS';
export const OPIssueSaleMain_SAVE_FAIL = 'OPIssueSaleMain_SAVE_FAIL';

export const BounceItem_SAVE_REQUEST = 'BounceItem_SAVE_REQUEST';
export const BounceItem_SAVE_SUCCESS = 'BounceItem_SAVE_SUCCESS';
export const BounceItem_SAVE_FAIL = 'BounceItem_SAVE_FAIL';

export const RxReview_SAVE_REQUEST = 'RxReview_SAVE_REQUEST';
export const RxReview_SAVE_SUCCESS = 'RxReview_SAVE_SUCCESS';
export const RxReview_SAVE_FAIL = 'RxReview_SAVE_FAIL';

export const OPIssueSaleDetails_SAVE_REQUEST = 'OPIssueSaleDetails_SAVE_REQUEST';
export const OPIssueSaleDetails_SAVE_SUCCESS = 'OPIssueSaleDetails_SAVE_SUCCESS';
export const OPIssueSaleDetails_SAVE_FAIL = 'OPIssueSaleDetails_SAVE_FAIL';


export const EmpStoreAccessData_SAVE_REQUEST = 'EmpStoreAccessData_SAVE_REQUEST';
export const EmpStoreAccessData_SAVE_SUCCESS = 'EmpStoreAccessData_SAVE_SUCCESS';
export const EmpStoreAccessData_SAVE_FAIL = 'EmpStoreAccessData_SAVE_FAIL';

export const OPIssueSaleReturnMain_SAVE_REQUEST = 'OPIssueSaleReturnMain_SAVE_REQUEST';
export const OPIssueSaleReturnMain_SAVE_SUCCESS = 'OPIssueSaleReturnMain_SAVE_SUCCESS';
export const OPIssueSaleReturnMain_SAVE_FAIL = 'OPIssueSaleReturnMain_SAVE_FAIL';

export const StockAdj_SAVE_REQUEST = 'StockAdj_SAVE_REQUEST';
export const StockAdj_SAVE_SUCCESS = 'StockAdj_SAVE_SUCCESS';
export const StockAdj_SAVE_FAIL = 'StockAdj_SAVE_FAIL';

export const BatchExpiry_SAVE_REQUEST = 'BatchExpiry_SAVE_REQUEST';
export const BatchExpiry_SAVE_SUCCESS = 'BatchExpiry_SAVE_SUCCESS';
export const BatchExpiry_SAVE_FAIL = 'BatchExpiry_SAVE_FAIL';

export const ItemRoute_SAVE_REQUEST = 'ItemRoute_SAVE_REQUEST';
export const ItemRoute_SAVE_SUCCESS = 'ItemRoute_SAVE_SUCCESS';
export const ItemRoute_SAVE_FAIL = 'ItemRoute_SAVE_FAIL';

export const ItemRouteForm_SAVE_REQUEST = 'ItemRouteForm_SAVE_REQUEST';
export const ItemRouteForm_SAVE_SUCCESS = 'ItemRouteForm_SAVE_SUCCESS';
export const ItemRouteForm_SAVE_FAIL = 'ItemRouteForm_SAVE_FAIL';



export const Frequency_SAVE_REQUEST = 'Frequency_SAVE_REQUEST';
export const Frequency_SAVE_SUCCESS = 'Frequency_SAVE_SUCCESS';
export const Frequency_SAVE_FAIL = 'Frequency_SAVE_FAIL';

export const ItemDisc_SAVE_REQUEST = 'ItemDisc_SAVE_REQUEST';
export const ItemDisc_SAVE_SUCCESS = 'ItemDisc_SAVE_SUCCESS';
export const ItemDisc_SAVE_FAIL = 'ItemDisc_SAVE_FAIL';

export const CompanyNonDiscountable_SAVE_REQUEST = 'CompanyNonDiscountable_SAVE_REQUEST';
export const CompanyNonDiscountable_SAVE_SUCCESS = 'CompanyNonDiscountable_SAVE_SUCCESS';
export const CompanyNonDiscountable_SAVE_FAIL = 'CompanyNonDiscountable_SAVE_FAIL';

export const CompanyItemExclusion_SAVE_REQUEST = 'CompanyItemExclusion_SAVE_REQUEST';
export const CompanyItemExclusion_SAVE_SUCCESS = 'CompanyItemExclusion_SAVE_SUCCESS';
export const CompanyItemExclusion_SAVE_FAIL = 'CompanyItemExclusion_SAVE_FAIL';

export const saveItemDiscount_LIST_REQUEST = 'saveItemDiscount_LIST_REQUEST';
export const saveItemDiscount_LIST_SUCCESS = 'saveItemDiscount_LIST_SUCCESS';
export const saveItemDiscount_LIST_FAIL = 'saveItemDiscount_LIST_FAIL';

export const saveItemNameSetup_LIST_REQUEST = 'saveItemNameSetup_LIST_REQUEST';
export const saveItemNameSetup_LIST_SUCCESS = 'saveItemNameSetup_LIST_SUCCESS';
export const saveItemNameSetup_LIST_FAIL = 'saveItemNameSetup_LIST_FAIL';

export const saveCommonMaster_SAVE_REQUEST = 'saveCommonMaster_SAVE_REQUEST';
export const saveCommonMaster_SAVE_SUCCESS = 'saveCommonMaster_SAVE_SUCCESS';
export const saveCommonMaster_SAVE_FAIL = 'saveCommonMaster_SAVE_FAIL';

export const savePractitioner_SAVE_REQUEST = 'savePractitioner_SAVE_REQUEST';
export const savePractitioner_SAVE_SUCCESS = 'savePractitioner_SAVE_SUCCESS';
export const savePractitioner_SAVE_FAIL = 'savePractitioner_SAVE_FAIL';

export const savePatientRegistration_SAVE_REQUEST = 'savePatientRegistration_SAVE_REQUEST';
export const savePatientRegistration_SAVE_SUCCESS = 'savePatientRegistration_SAVE_SUCCESS';
export const savePatientRegistration_SAVE_FAIL = 'savePatientRegistration_SAVE_FAIL';

export const UpdateOPIssueSaleAfterPrint_SAVE_REQUEST = 'UpdateOPIssueSaleAfterPrint_SAVE_REQUEST';
export const UpdateOPIssueSaleAfterPrint_SAVE_SUCCESS = 'UpdateOPIssueSaleAfterPrint_SAVE_SUCCESS';
export const UpdateOPIssueSaleAfterPrint_SAVE_FAIL = 'UpdateOPIssueSaleAfterPrint_SAVE_FAIL';

export const UpdateIPIssueAfterPrint_SAVE_REQUEST = 'UpdateIPIssueAfterPrint_SAVE_REQUEST';
export const UpdateIPIssueAfterPrint_SAVE_SUCCESS = 'UpdateIPIssueAfterPrint_SAVE_SUCCESS';
export const UpdateIPIssueAfterPrint_SAVE_FAIL = 'UpdateIPIssueAfterPrint_SAVE_FAIL';

export const UpdateReusableItemUsage_LIST_REQUEST = 'UpdateReusableItemUsage_LIST_REQUEST';
export const UpdateReusableItemUsage_LIST_SUCCESS = 'UpdateReusableItemUsage_LIST_SUCCESS';
export const UpdateReusableItemUsage_LIST_FAIL = 'UpdateReusableItemUsage_LIST_FAIL';

export const UpdateGrn_SAVE_REQUEST = 'UpdateGrn_SAVE_REQUEST';
export const UpdateGrn_SAVE_SUCCESS = 'UpdateGrn_SAVE_SUCCESS';
export const UpdateGrn_SAVE_FAIL = 'UpdateGrn_SAVE_FAIL';
//------------------------------------------------------------------------
export const Report_Facility_Mapping_LIST_REQUEST = 'Report_Facility_Mapping_SAVE_REQUEST';
export const Report_Facility_Mapping_LIST_SUCCESS = 'Report_Facility_Mapping_SAVE_SUCCESS';
export const Report_Facility_Mapping_LIST_FAIL = 'Report_Facility_Mapping_SAVE_FAIL';
